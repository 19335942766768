<template>
  <article class="facial_access">
    <!-- <envelope-fuseaccess></envelope-fuseaccess> -->
    <transition name="fade">
      <section
        class="facial_access__loaders"
        v-if="!complete_petition && active_request">
        <spin-loader></spin-loader>
        <percentage-loader :percentage="loading_percentage" ></percentage-loader>
      </section>
    </transition>

    <section class="facial_access__header">
      <fuseaccess-logo class="facial_access__header--logo"></fuseaccess-logo>
    </section>
    <transition name="fade">
      <div class="facial_access__content" v-if="expired_invitation">
        <section class="facial_access__success_invitation">
          <time-out-icon class="facial_access__success_invitation--icon"></time-out-icon>
          <div class="facial_access__success_invitation--title">
            <p><strong>¡Invitación no valida!</strong></p>
          </div>
          <div class="facial_access__success_invitation--text">
            <p>
              Lo sentimos, esta invitación ha <strong>caducado</strong> o ha sido <strong>cancelada</strong> por el anfitrión
            </p>
          </div>
        </section>
      </div>
    </transition>

    <transition name="fade">
      <div
        class="facial_access__content"
        v-if="loaded && !expired_invitation && !invitation_already_completed">

        <div class="facial_access__location">
          <section
            style="margin-top: 20px;"
            v-if="!invitation_already_completed && !expired_invitation">
            <div
              class="facial_access__dashed_section"
              :class="{'error_in_petition' : error_in_petition || error_plate}"
              v-if="show_form_section">
              <div
                class="facial_access__selfie_modal--close facial_access__dashed_section--close"
                @click="clearForm()">
                <close-icon :color="error_in_petition || error_plate ? '#D50000': '#BDBDBD'"></close-icon>
              </div>
              <div
                class="facial_access__success_invitation--text"
                :class="{'error_in_petition' : error_in_petition}"
                v-if="error_in_petition">
                <warning-icon></warning-icon>
                <p>
                  {{ repeat_plate_error ? 'Oops, tu registro no pudo ser completado con esa placa' : 'Oops, hubo un inconveniente en la comunicación' }}
                </p>
              </div>
              <div
                class="facial_access__success_invitation--text"
                :class="{'error_in_petition' : error_plate}"
                v-if="error_plate && (!company_invitation_form_vehicle_data)">
                <warning-icon></warning-icon>
                <p>
                  Oops, debes completar la información de tu vehículo
                </p>
              </div>
              <div
                class="facial_access__success_invitation--text"
                :class="{'error_in_petition' : error_plate}"
                v-if="error_plate && company_invitation_form_vehicle_data">
                <warning-icon></warning-icon>
                <p>
                  Oops, debes completar todos los campos del formulario
                </p>
              </div>
              <label class="facial_access__access_requirement--label" >Invitado: <strong>{{ guest_info.name }}</strong></label>
              <img
                :class="{'error_in_petition' : error_in_petition}"
                :src="guest_url_image"
                alt="guest image"
                v-if="guest_url_image != null">

              <div class="facial_access__plate_info" v-if="access_types.includes(11) || company_invitation_form_vehicle_data">
                <p class="facial_access__plate_info--title" v-if="company_invitation_form_vehicle_data">No. de identificación</p>
                <div class="facial_access__vehicle_type" v-if="company_invitation_form_vehicle_data">
                  <input
                    v-model="guest_info.rut"
                    type="text"
                    name="user_rut"
                    class="facial_access__vehicle_type--input"
                    />
                </div>

                <p class="facial_access__plate_info--title" v-if="access_types.includes(11) || vehicular_type_location">Tipo de vehículo</p>
                <div class="facial_access__vehicle_type" v-if="access_types.includes(11) || vehicular_type_location">
                  <select id="vehicle_type" class="facial_access__vehicle_type--select" name="vehicle_type" v-model="guest_info.vehicle_type">
                    <option value="15">Carro</option>
                    <option value="16">Moto</option>
                  </select>
                </div>

                <p class="facial_access__plate_info--title" v-if="access_types.includes(11) || vehicular_type_location">Número de placa</p>
                <div class="facial_access__vehicle_type" v-if="access_types.includes(11) || vehicular_type_location">
                  <input
                    v-model="guest_info.plate_number"
                    type="text"
                    name="user_photo"
                    class="facial_access__vehicle_type--input"
                  />
                </div>
              </div>

              <p v-if= "access_invitation_approved" class="facial_access__plate_info--title">No. de identificación</p>
              <div v-if= "access_invitation_approved" class="facial_access__vehicle_type">
                <input
                  v-model="guest_info.rut"
                  type="text"
                  name="user_rut"
                  class="facial_access__vehicle_type--input"
                  />
              </div>

              <div class="facial_access__politics">
                <p>
                  *Al finalizar el registro, aceptas las políticas de privacidad de <a href="https://politicas.fusepong.com/#/" target="_blank">Fuseaccess</a> <span v-if="privacy_policy_link != null && privacy_policy_link != undefined">y <a :href="privacy_policy_link" target="_blank">{{company_name}}</a></span>
                </p>
              </div>
              <div
                class="facial_access__access_requirement--selfie_btn"
                @click="loadInfoToSend" v-if="!error_in_petition && !error_plate">
                  Finalizar registro
              </div>
              <section v-else>
                <div
                  class="facial_access__access_requirement--selfie_btn"
                  @click="loadInfoToSend">
                    Reintentar registro
                </div>
                <div
                  v-if="access_types.includes(10)"
                  class="facial_access__access_requirement--secundary_btn"
                  @click="clearForm()">
                    Tomar otra foto
                </div>
              </section>
            </div>
            <div v-else>
              <p class="facial_access__location--text principal">
                Hola <strong>{{ guest_info.name }}</strong><br>Tienes una invitación a:
              </p>
              <div class="facial_access__location--division_line"></div>
              <div v-if="customized_invitation">
                <p class="facial_access__location--text">
                  <strong>Edificio:</strong> {{ company_name +" - "+location_name }}<br>
                  <strong>Dirección:</strong> {{address}}<br>
                  <strong>Por:</strong> {{ sponsor === null || sponsor === '' ? 'Administración - ' + company_name : sponsor }}<br>
                  <strong>Hora:</strong> {{ new Date( params_begin_server * 1000) | moment("h:mm a")}} a {{ new Date( params_end_server * 1000) | moment("h:mm a")}}<br>
                  <strong>Fechas:</strong> <br>
                  <span v-for="date in formatted_invitations_dates" :key="date">{{ date }}<br></span>
                </p>

              </div>
              <div v-else>
                <p class="facial_access__location--text">
                  <strong>Edificio:</strong> {{ company_name +" - "+location_name }}<br>
                  <strong>Dirección:</strong> {{address}}<br>
                  <strong>Por:</strong> {{ sponsor === null || sponsor === '' ? 'Administración - ' + company_name : sponsor }}<br>
                  <strong>Desde:</strong> {{ new Date( params_begin_server * 1000) | moment("LL")}} {{ new Date( params_begin_server * 1000) | moment("h:mm a")}}<br>
                  <strong>Hasta:</strong> {{ new Date( params_end_server * 1000) | moment("LL")}} {{ new Date( params_end_server * 1000) | moment("h:mm a")}}
                </p>
              </div>
              <div
                class="facial_access__access_requirement--selfie_btn"
                @click="access_types.includes(10) ? show_selfie_section = true : show_form_section = true">
                Realizar registro
              </div>
            </div>
          </section>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <section
        class="facial_access__selfie_modal"
        v-if="show_selfie_section && guest_url_image == null">
        <div
          class="facial_access__access_requirement facial_access__selfie_modal--content"
          v-if="!invitation_already_completed && !expired_invitation">
          <div
            class="facial_access__selfie_modal--close"
            @click="show_selfie_section = false">
            <close-icon></close-icon>
          </div>
          <label
            for="selfie_uploader"
            class="facial_access__dashed_section"
            v-if="guest_url_image == null">
            <p class="facial_access__take_photo--title">Tómate una selfie</p>
            <section class="facial_access__take_photo facial_access__photo_button">
              <selfie-icon
                class="facial_access__take_photo--principal_svg"
                color="#757575"></selfie-icon>
              <div class="facial_access__take_photo--description">
                <p>
                  Click Aqui <touch-icon color="#757575"></touch-icon>
                </p>
              </div>
            </section>
            <input
                id="selfie_uploader"
                type="file"
                name="user_photo"
                accept="image/*"
                capture="user"
                style="display:none"
                @change="uploadGuestImage($event)"/>
          </label>
        </div>
      </section>
    </transition>

    <transition name="fade">
      <div class="facial_access__content" v-if="invitation_already_completed && !expired_invitation">
        <section class="facial_access__success_invitation">
          <success-icon class="facial_access__success_invitation--icon"></success-icon>
          <div class="facial_access__success_invitation--title">
            <p><strong>¡Registro exitoso!</strong></p>
          </div>
          <div class="facial_access__success_invitation--text">
            <p v-if="invitation.approved">
              <strong>{{ sponsor === null || sponsor === '' ? 'Administración - ' + company_name : sponsor }}</strong> te espera en <br>
              <strong>{{ location_name }}</strong>
            </p>
            <p v-else>
              Gracias, serás notificado cuando la invitación sea aprobada para que puedas ingresar.
            </p>
          </div>
          <div v-if="invitation.approved">
            <div v-if="access_types.includes(4)" class="facial_access__politics" style="margin-top: 35px;">
              <p>Usa el siguiente QR para poder acceder a la locación de la invitación</p>
              <div
                class="facial_access__access_requirement--selfie_btn"
                style="margin-top: 10px;"
                @click="redirectToQR">
                Abrir QR
              </div>
            </div>
          </div>
          <div v-else-if="access_types.includes(3)" class="facial_access__politics" style="margin-top: 35px;">
            <p>Revisa tu correo electronico para abrir el QR que te permita acceder a la locación de la invitación</p>
          </div>
        </section>
      </div>
    </transition>

    <div class="facial_access__footer" v-if="loaded">
      <img src="@/assets/img/faccess_logo.svg">
    </div>
  </article>
</template>
<script>
  // Import component
  import Loading from 'vue-loading-overlay';
  import PercentageLoader from '/src/components/shared/icons/PercentageLoader.vue'
  import SelfieIcon from '/src/components/shared/icons/SelfieIcon.vue'
  import TouchIcon from '/src/components/shared/icons/TouchIcon.vue'
  import CloseIcon from '/src/components/shared/icons/CloseIcon.vue'
  import EnvelopeFuseaccess from '/src/components/shared/icons/EnvelopeFuseaccess.vue'
  import SpinLoader from '/src/components/shared/SpinLoader.vue'
  import FuseaccessLogo from '/src/components/shared/icons/FuseaccessLogo.vue'
  import SuccessIcon from '/src/components/shared/icons/SuccessIcon.vue'
  import TimeOutIcon from '/src/components/shared/icons/TimeOutIcon.vue'
  import WarningIcon from '/src/components/shared/icons/WarningIcon.vue'
  // Import stylesheet
  import 'vue-loading-overlay/dist/vue-loading.css';

  export default {
    components:{
      Loading,
      SelfieIcon,
      TouchIcon,
      EnvelopeFuseaccess,
      CloseIcon,
      PercentageLoader,
      SpinLoader,
      FuseaccessLogo,
      SuccessIcon,
      TimeOutIcon,
      WarningIcon
    },
    data(){
      return{
        access_invitation_approved: false,
        active_request: false,
        complete_petition: false,
        complete_charge: false,
        show_selfie_section: false,
        expired_invitation: false,
        code: null,
        invitation_already_completed: false,
        guest_info: {
          rut: '',
          name: '',
          email: '',
          location_id: '',
          sublocation_id: '',
          user_photo: null,
          facial_invitation: true,
          invited_client_id:'',
          vehicle_type: '',
          plate_number: ''
        },
        guest_url_image: null,
        params_user_id: null,
        params_time_send: null,
        params_device_id: null,
        params_begin_server: null,
        params_end_server: null,
        params_user_id_encrypt: null,
        params_invited_client_id: null,
        params_location_name: null,
        params_generated_by_bot: false,
        sponsor: null,
        company_name: null,
        address: null,
        privacy_policy_link: null,
        // expired_previous_entry: false,
        invitation: [],
        company: [],
        location_name: '',
        loaded: false,
        // booking_office: '',
        location_type:'',
        isLoading: false,
        loading_percentage: 0,
        error_plate: false,
        invitation_used: false,
        access_types: [],
        show_form_section: false,
        error_in_petition: false,
        repeat_plate_error: false,
        customized_invitation: false,
        formatted_invitations_dates: [],
        vehicular_type_location: false,
        company_invitation_form_vehicle_data: false
      }
    },
    mounted() {
      try{
        let vm = this;
        let params = this.$router.app._route.query
        console.log("<<<<<<<<<<<<<<<<<<<< Router");
        console.log(this.$router);
        console.log("<<<<<<<<<<<<<<<<<<<< Params");
        console.log(params);
        if (JSON.stringify(params)!=='{}' && params.device_id !== '' && params.invited_client_id !== '') {
          this.params_device_id = params.device_id
          this.params_user_id_encrypt = params.user_id_encrypt
          this.params_invited_client_id = params.invited_client_id
          this.params_generated_by_bot = params.gbot != null && params.gbot != undefined && params.gbot == 1 ? true : false
          console.log("<<<<<<<< Generated by bot ? ", this.params_generated_by_bot);
          this.getInvitation()

          setTimeout(() => this.isLoading = false, 1000)
          // this.validatePreviousEntry()
        }
        document.title = 'Invitación de Acceso';
      }catch(e){
        console.log('There are no params ', e);
      }
    },
    destroy(){
      clearInterval(vm.generate_qr)
    },
    watch: {
      'guest_info.plate_number'(newValue){
        this.guest_info.plate_number = this.guest_info.plate_number.toUpperCase();
      }
    },

    methods: {
      // convertToUppercase(){
      //   this.guest_info.plate_number = this.guest_info.plate_number.toUpperCase()
      // },
      invitationHasExpired(){
        return new Date(this.params_end_server * 1000) < new Date() || this.invitation.invitation_state == 'CANCELLED' || this.invitation.invitation_state == 'COMPLETED'
      },
      invitationWasCompleted(){
        return this.invitation.invitation_state == 'USED' || this.invitation_used == true
      },
      // validatePreviousEntry(){
      //   try {
      //     this.$http.post('app/bookings/invited_client_last_access', { invited_client_id: this.params_invited_client_id},)
      //     .then(function(response){
      //         this.expired_previous_entry = response.body;
      //         console.log('this.expired_previous_entry', this.expired_previous_entry)
      //     }, function(response){
      //         console.log(response.body.data)
      //     })
      //   } catch (e) {
      //     console.log(e.message)
      //   }
      // },
      getInvitation(){
        console.log("<<<<<<<<<<<<<<<<<<<<<<< Get Invitation");
        this.isLoading = true
        try {
          this.$http.get('get_invited_clients_by_id?invited_client_id='+this.params_invited_client_id)
          .then(function(response){
            console.log('response all',response);
            this.access_invitation_approved = response.body.access_invitation_approved
            this.guest_info.name = response.body.invitation.name
            this.guest_info.email = response.body.invitation.email
            this.guest_info.location_id = response.body.invitation.location_id
            this.guest_info.invited_client_id = this.params_invited_client_id
            this.invitation = response.body.invitation;
            this.location_name = response.body.location;
            this.guest_info.sublocation_id = response.body.sublocation
            this.params_begin_server = Math.floor(new Date(this.invitation.start_time).getTime()/1000)
            this.params_end_server = Math.floor(new Date(this.invitation.end_time).getTime()/1000)
            this.company = response.body.company;
            this.location_name = response.body.location;
            this.params_user_id = this.invitation.user_id;
            this.location_type = response.body.location_type
            this.sponsor = response.body.sponsor
            this.company_name = response.body.company_name
            this.address = response.body.address
            this.privacy_policy_link = response.body.privacy_policy_link
            this.invitation_used = response.body.invitation_used
            this.access_types = response.body.access_types
            console.log(this.access_types)
            this.expired_invitation = this.invitationHasExpired()
            this.invitation_already_completed = this.invitationWasCompleted()
            this.customized_invitation = response.body.customized_invitation
            this.formatted_invitations_dates = response.body.formatted_invitations_dates
            this.loaded = true
            this.vehicular_type_location = response.body.vehicular_type_location
            this.company_invitation_form_vehicle_data = response.body.form_vehicle_data

            console.log("EXPIRED ?", this.expired_invitation)
            console.log("IT WAS COMPLETED ?", this.invitation_already_completed)

            // this.booking_office = response.body.booking_office;
            // console.log('booking_office', response.body.booking_office)

          }, function(response){
            console.log(response.body.data)
          })
        } catch (e) {
          console.log(e.message)
        }
      },
      async uploadGuestImage(event){
        console.log(event);

        let vm = this
        const guest_img = event.target.files[0]
        const extension = event.target.files[0].type.split('/')[1]

        console.log("PESO ORIGINAL DE LA FOTO " + guest_img.size)
        console.log("EXTENSION " + extension)

        if(extension != "jpg" && extension != "jpeg"){
          alert('Solo se admite el formato jpg o jpeg')

        } else {
          vm.isLoading = true
          setTimeout(() => vm.isLoading = false, 1000);

          document.querySelector('.facial_access__access_requirement').scrollIntoView();


          const blob = await this.compressImage(guest_img, parseInt(10));
          vm.guest_url_image = window.URL.createObjectURL(guest_img);
          vm.show_selfie_section = false
          vm.error_in_petition = false
          vm.show_form_section = true
          console.log("PESO DE LA FOTO COMPRIMIDA " + blob.size)
          vm.loadUserImage(blob)
        }
      },
      compressImage(imageFile, quality){
        return new Promise((resolve, reject) => {
          const $canvas = document.createElement("canvas")
          const imagen = new Image();
          var oldWidth , oldHeight , newHeight , newWidth = 1000;

          imagen.onload = () => {
            oldWidth = imagen.width;
            oldHeight = imagen.height;
            newHeight = Math.floor(oldHeight / oldWidth * newWidth)
            $canvas.width = newWidth;
            $canvas.height = newHeight;
            $canvas.getContext("2d").drawImage(imagen, 0, 0, newWidth,newHeight);
            $canvas.toBlob(
              (blob) => {
                if (blob === null) {
                  return reject(blob);
                } else {
                  resolve(blob);
                }
              },
              "image/jpeg",
              quality / 100
            );
          };
          imagen.src = URL.createObjectURL(imageFile);
        });
      },
      loadUserImage(file){
        let reader = new FileReader()
        const vm = this

        reader.readAsDataURL(file);
        reader.onload = () => {
          vm.guest_info.user_photo = reader.result
        }
      },
      loadInfoToSend(){
        let vm = this;
        if (this.access_types.includes(11) || this.company_invitation_form_vehicle_data){
          if((this.access_types.includes(11) && (this.guest_info.vehicle_type === '' || this.guest_info.plate_number === '')) ||
          (this.company_invitation_form_vehicle_data && (!this.vehicular_type_location && this.guest_info.rut === '')) ||
          (this.company_invitation_form_vehicle_data && (this.vehicular_type_location && (this.guest_info.rut === '' || this.guest_info.vehicle_type === '' || this.guest_info.plate_number === '')))){
            this.error_plate = true
          }else{
            vm.getInvitation()
            vm.loading_percentage = 0
            vm.active_request = true
            vm.complete_petition = false
            setTimeout(vm.sendForm, 1300)
          }
        }else{
          vm.getInvitation()
          vm.loading_percentage = 0
          vm.active_request = true
          vm.complete_petition = false
          setTimeout(vm.sendForm, 1300)
        }
      },
      sendForm(){
        var vm = this
        console.log("<<<<<<<<<<<<<<<<<<<<<<<<<<<<<Load Photo");
        console.log(vm.guest_info.user_photo);

        vm.loadPercentage(8);
        if(!this.expired_invitation){
          console.log("<<<<<<<<<<<<<<<<<<<<<<< Guest Info", vm.guest_info)
          vm.$http.post('app/users/open_external_access_request', {
            data: this.encrypt({ attributes: vm.guest_info }).toString()
          })
          .then(response => {
            vm.loadPercentage(2);
            if(response.ok){
              scrollTo(0,0)
              vm.invitation_already_completed = true
              setTimeout(function(){
                console.log('response ok', response)
                vm.active_request = false
                vm.complete_petition = true
                vm.error_in_petition = false
                // alert('Se procesó correctamente la información !');
              }, 2000)
            }

          })
          .catch(e => {
            vm.loadPercentage(2);
            console.log('response fail', e)
            var responseMsg = e.body.ans
            console.error(e)
            setTimeout(function(){
              vm.active_request = false
              vm.complete_petition = true
              vm.error_in_petition = true
              vm.repeat_plate_error = responseMsg.includes("plate not allowed")
              // alert('Tu solicitud no pudo ser procesada, ' + responseMsg)
              // vm.resetForm()
              console.log("Error in petition", e);
            }, 2000)
          })
        } else {
          setTimeout(function(){
            vm.active_request = false
            vm.complete_petition = true
          }, 2000)
        }
      },
      resetForm(){
        this.guest_url_image = null;
      },
      clearForm(){
        this.show_selfie_section = this.access_types.includes(10)
        this.show_form_section = false
        this.error_in_petition = false
        this.error_plate = false
        this.guest_url_image = null
        this.guest_info.user_photo= null
        this.guest_info.plate_number = ''
        this.guest_info.vehicle_type = ''
      },
      loadPercentage(number){
        for (var i = 0; i < number; i++) {
          this.sumPercentage()
        }
      },
      sumPercentage(){
        if(this.loading_percentage < 100){
          this.loading_percentage += 10
        }
      },
      redirectToQR(){
        let params = this.$router.app._route.query
        if(params.hasOwnProperty("enable_indexes")) window.open(`https://invitation.fusepong.com/#/?enable_indexes&user_id_encrypt=${this.params_user_id_encrypt}&invited_client_id=${this.params_invited_client_id}`, '_blank')
        else window.open(`https://invitation.fusepong.com/#/?device_id=${this.params_device_id}&user_id_encrypt=${this.params_user_id_encrypt}&invited_client_id=${this.params_invited_client_id}`, '_blank')
      }
    }
  }
</script>
